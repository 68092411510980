import withHydrationOnDemand from 'react-hydration-on-demand'
import dynamic from 'next/dynamic'

import { WidgetTypes } from '../WidgetTypes'

const AccordionContainer = dynamic(() =>
  import('../AccordionWidgetRenderer').then(
    (mod) => mod.AccordionContainerWidgetRenderer,
  ),
)

const BannerSlider = dynamic(() =>
  import('../BannerWidgetRenderer').then(
    (mod) => mod.BannerSliderWidgetRenderer,
  ),
)

const BannerComponent = dynamic(() =>
  import('../BannerWidgetRenderer').then((mod) => mod.BannerWidgetRenderer),
)

const SimpleTextReadMoreComponent = dynamic(() =>
  import('../SimpleTextReadMoreRenderer').then(
    (mod) => mod.SimpleTextReadMoreRenderer,
  ),
)

const AccordionItem = dynamic(() =>
  import('../AccordionWidgetRenderer').then(
    (mod) => mod.AccordionItemWidgetRenderer,
  ),
)

const BestSellers = dynamic(() =>
  import('../BestSellersWidgetRenderer').then(
    (mod) => mod.BestSellersWidgetRenderer,
  ),
)

const ButtonCollection = dynamic(() =>
  import('../ButtonCollectionWidgetRenderer').then(
    (mod) => mod.ButtonCollectionWidgetRenderer,
  ),
)

const BuyingRightNow = dynamic(() =>
  import('../BuyingRightNowRenderer').then((mod) => mod.BuyingRightNowRenderer),
)

const CategorySet = dynamic(() =>
  import('../CategorySetWidgetRenderer').then(
    (mod) => mod.CategorySetWidgetRenderer,
  ),
)

const CategorySetItem = dynamic(() =>
  import('../CategorySetWidgetRenderer').then(
    (mod) => mod.CategorySetItemWidgetRenderer,
  ),
)

const DynamicReferral = dynamic(() =>
  import('../DynamicReferralRenderer').then(
    (mod) => mod.DynamicReferralRenderer,
  ),
)

const LogoImageCollection = dynamic(() =>
  import('../LogoImageCollectionRenderer').then(
    (mod) => mod.LogoImageCollectionRenderer,
  ),
)

const Editorial = dynamic(() =>
  import('../EditorialWidgetRenderer').then(
    (mod) => mod.EditorialWidgetRenderer,
  ),
)

const EmailEngagementModal = dynamic(() =>
  import('../EmailEngagementModalRenderer').then(
    (mod) => mod.EmailEngagementModalRenderer,
  ),
)

const FeaturedProducts = dynamic(() =>
  import('../FeaturedProductsRenderer').then(
    (mod) => mod.FeaturedProductsRenderer,
  ),
)

const HelpCentreCollection = dynamic(() =>
  import('../HelpCentreCollectionWidgetRenderer').then(
    (mod) => mod.HelpCentreCollectionWidgetRenderer,
  ),
)

const ImageCardSet = dynamic(() =>
  import('../ImageCardCarouselRenderer').then(
    (mod) => mod.ImageCardSetRenderer,
  ),
)

const ImageOverlayCollection = dynamic(() =>
  import('../ImageOverlayCollectionRenderer').then(
    (mod) => mod.ImageOverlayCollectionRenderer,
  ),
)

const ProductBlockCarousel = dynamic(() =>
  import('../ProductBlockCarouselRenderer').then(
    (mod) => mod.ProductBlockCarouselRenderer,
  ),
)

const PropositionBar = dynamic(() =>
  import('../PropositionBarRenderer').then((mod) => mod.PropositionBarRenderer),
)

const SectionPeek = dynamic(() =>
  import('../SectionPeekWidgetRenderer').then(
    (mod) => mod.SectionPeekWidgetRenderer,
  ),
)

const ShopTheLook = dynamic(() =>
  import('../ShopTheLookWidgetRenderer').then(
    (mod) => mod.ShopTheLookWidgetRenderer,
  ),
)

const SimpleText = dynamic(() =>
  import('../SimpleTextWidgetRenderer').then(
    (mod) => mod.SimpleTextWidgetRenderer,
  ),
)

const ImageTextBlock = dynamic(() =>
  import('../ImageTextBlockRenderer').then((mod) => mod.ImageTextBlockRenderer),
)

const ImageSelectSlider = dynamic(() =>
  import('../ImageSelectSliderRenderer').then(
    (mod) => mod.ImageSelectSliderRenderer,
  ),
)

const TabbedSet = dynamic(() =>
  import('../TabbedSetWidgetRenderer').then(
    (mod) => mod.TabbedSetWidgetRenderer,
  ),
)

const SocialIconCollection = dynamic(() =>
  import('../SocialIconCollectionRenderer').then(
    (mod) => mod.SocialIconCollectionRenderer,
  ),
)

const StoryStream = dynamic(() =>
  import('../StoryStreamWidgetRenderer').then(
    (mod) => mod.StoryStreamWidgetRenderer,
  ),
)

const ProductShowcase = dynamic(() =>
  import('../ProductsShowcaseRenderer').then(
    (mod) => mod.ProductsShowcaseRenderer,
  ),
)

const FlagshipBanner = dynamic(() =>
  import('../FlagshipRenderer').then((mod) => mod.FlagshipRenderer),
)

const ReferralStepGuide = dynamic(() =>
  import('../ReferralStepGuideRenderer').then(
    (mod) => mod.ReferralStepGuideRenderer,
  ),
)

const VideoGallery = dynamic(() =>
  import('../VideoGalleryRenderer').then((mod) => mod.VideoGalleryRenderer),
)

const VideoHeroBanner = dynamic(() =>
  import('../VideoHeroBannerRenderer').then(
    (mod) => mod.VideoHeroBannerRenderer,
  ),
)

const VisualDivider = dynamic(() =>
  import('../VisualDividerWidgetRenderer').then(
    (mod) => mod.VisualDividerWidgetRenderer,
  ),
)

const NotSupported = dynamic(() =>
  import('../NotSupportedWidget').then((mod) => mod.NotSupportedWidget),
)

const BrandsPage = dynamic(() =>
  import('../BrandsPageWidgetRenderer').then(
    (mod) => mod.BrandsPageWidgetRenderer,
  ),
)

const StripBanner = dynamic(() =>
  import('../StripBannerRenderer').then((mod) => mod.StripBannerRenderer),
)

const CoverageCalculator = dynamic(() =>
  import('../CoverageCalculatorRenderer').then(
    (mod) => mod.CoverageCalculatorRenderer,
  ),
)

const SimpleTitleAndCTA = dynamic(() =>
  import('../SimpleTitleAndCtaRenderer').then(
    (mod) => mod.SimpleTitleAndCtaRenderer,
  ),
)

const AboutUsVideo = dynamic(() =>
  import('../AboutUsVideoRenderer').then((mod) => mod.AboutUsVideoRenderer),
)

const WaitlistSignup = dynamic(() =>
  import('../WaitlistSignupWidgetRenderer').then(
    (mod) => mod.WaitlistSignupWidgetRenderer,
  ),
)

const SwatchSelectSlider = dynamic(() =>
  import('../SwatchSelectorSliderWidgetRenderer').then(
    (mod) => mod.SwatchSelectorSliderWidgetRenderer,
  ),
)

const BuildYourOwnBundle = dynamic(() =>
  import('../BuildYourOwnBundleRenderer').then(
    (mod) => mod.BuildYourOwnBundleRenderer,
  ),
)

const ShopTheRoom = dynamic(() =>
  import('../ShopTheRoomWidgetRenderer').then(
    (mod) => mod.ShopTheRoomWidgetRenderer,
  ),
)

const TrustPilotCarousel = dynamic(() =>
  import('../TrustPilotCarousel').then((mod) => mod.TrustPilotCarouselRenderer),
)

const DispatchDateAndCountdown = dynamic(() =>
  import('../DispatchAndDateCountdownWidgetRenderer').then(
    (mod) => mod.DispatchAndDateCountDownWidgetRenderer,
  ),
)

const PersonalisedRecommendations = dynamic(() =>
  import('../PersonalisedRecommendationsWidgetRenderer').then(
    (mod) => mod.PersonalisedRecommendationsRenderer,
  ),
)

const AccordionContainerComponent = withHydrationOnDemand({
  on: ['visible'],
})(AccordionContainer)

const AccordionItemComponent = withHydrationOnDemand({
  on: ['visible'],
})(AccordionItem)

const BannerSliderComponent = withHydrationOnDemand({
  on: ['visible'],
})(BannerSlider)

const BestSellersComponent = withHydrationOnDemand({
  on: ['visible'],
})(BestSellers)

const ButtonCollectionComponent = withHydrationOnDemand({
  on: ['visible'],
})(ButtonCollection)

const BuyingRightNowComponent = withHydrationOnDemand({
  on: ['visible'],
})(BuyingRightNow)

const CategorySetComponent = withHydrationOnDemand({
  on: ['visible'],
})(CategorySet)

const CategorySetItemComponent = withHydrationOnDemand({
  on: ['visible'],
})(CategorySetItem)

const DynamicReferralComponent = withHydrationOnDemand({
  on: ['visible'],
})(DynamicReferral)

const LogoImageCollectionComponent = withHydrationOnDemand({
  on: ['visible'],
})(LogoImageCollection)

const EditorialComponent = withHydrationOnDemand({
  on: ['visible'],
})(Editorial)

const EmailEngagementModalComponent = withHydrationOnDemand({
  on: ['visible'],
})(EmailEngagementModal)

const FeaturedProductsComponent = withHydrationOnDemand({
  on: ['visible'],
})(FeaturedProducts)

const HelpCentreCollectionComponent = withHydrationOnDemand({
  on: ['visible'],
})(HelpCentreCollection)

const ImageCardSetComponent = withHydrationOnDemand({
  on: ['visible'],
})(ImageCardSet)

const ImageOverlayCollectionComponent = withHydrationOnDemand({
  on: ['visible'],
})(ImageOverlayCollection)

const ProductBlockCarouselComponent = withHydrationOnDemand({
  on: ['visible'],
})(ProductBlockCarousel)

const PropositionBarComponent = withHydrationOnDemand({
  on: ['visible'],
})(PropositionBar)

const SectionPeekComponent = withHydrationOnDemand({
  on: ['visible'],
})(SectionPeek)

const ShopTheLookComponent = withHydrationOnDemand({
  on: ['visible'],
})(ShopTheLook)

const SimpleTextComponent = withHydrationOnDemand({
  on: ['visible'],
})(SimpleText)

const ImageTextBlockComponent = withHydrationOnDemand({
  on: ['visible'],
})(ImageTextBlock)

const TabbedSetComponent = withHydrationOnDemand({
  on: ['visible'],
})(TabbedSet)

const SocialIconCollectionComponent = withHydrationOnDemand({
  on: ['visible'],
})(SocialIconCollection)

const StoryStreamComponent = withHydrationOnDemand({
  on: ['visible'],
})(StoryStream)

const ProductShowcaseComponent = withHydrationOnDemand({
  on: ['visible'],
})(ProductShowcase)

const FlagshipBannerComponent = withHydrationOnDemand({
  on: ['visible'],
})(FlagshipBanner)

const ReferralStepGuideComponent = withHydrationOnDemand({
  on: ['visible'],
})(ReferralStepGuide)

const VideoGalleryComponent = withHydrationOnDemand({
  on: ['visible'],
})(VideoGallery)

const VideoHeroBannerComponent = withHydrationOnDemand({
  on: ['visible'],
})(VideoHeroBanner)

const VisualDividerComponent = withHydrationOnDemand({
  on: ['visible'],
})(VisualDivider)

const NotSupportedComponent = withHydrationOnDemand({
  on: ['visible'],
})(NotSupported)

const BrandsPageComponent = withHydrationOnDemand({
  on: ['visible'],
})(BrandsPage)

const StripBannerComponent = withHydrationOnDemand({
  on: ['visible'],
})(StripBanner)

const CoverageCalculatorComponent = withHydrationOnDemand({
  on: ['visible'],
})(CoverageCalculator)

const SimpleTitleAndCTAComponent = withHydrationOnDemand({
  on: ['visible'],
})(SimpleTitleAndCTA)

const AboutUsVideoComponent = withHydrationOnDemand({
  on: ['visible'],
})(AboutUsVideo)

const WaitlistSignupComponent = withHydrationOnDemand({
  on: ['visible'],
})(WaitlistSignup)

const SwatchSelectSliderComponent = withHydrationOnDemand({
  on: ['visible'],
})(SwatchSelectSlider)

const BuildYourOwnBundleComponent = withHydrationOnDemand({
  on: ['visible'],
})(BuildYourOwnBundle)

const ImageSelectSliderComponent = withHydrationOnDemand({
  on: ['visible'],
})(ImageSelectSlider)

const ShopTheRoomComponent = withHydrationOnDemand({
  on: ['visible'],
})(ShopTheRoom)

const TrustPilotCarouselComponent = withHydrationOnDemand({
  on: ['visible'],
})(TrustPilotCarousel)

const DispatchDateAndCountdownComponent = withHydrationOnDemand({
  on: ['visible'],
})(DispatchDateAndCountdown)

const PersonalisedRecommendationsComponent = withHydrationOnDemand({
  on: ['visible'],
})(PersonalisedRecommendations)

const widgetRendererLoaders: {
  [key: string]: () => React.ComponentType<any>
} = {
  AccordionContainer: () => AccordionContainerComponent,
  AccordionItem: () => AccordionItemComponent,
  Banner: () => BannerComponent,
  BannerSlider: () => BannerSliderComponent,
  BestSellers: () => BestSellersComponent,
  ButtonCollection: () => ButtonCollectionComponent,
  BuyingRightNow: () => BuyingRightNowComponent,
  CategorySet: () => CategorySetComponent,
  CategorySetItem: () => CategorySetItemComponent,
  DynamicReferral: () => DynamicReferralComponent,
  LogoImageCollection: () => LogoImageCollectionComponent,
  Editorial: () => EditorialComponent,
  EmailEngagementModal: () => EmailEngagementModalComponent,
  FeaturedProducts: () => FeaturedProductsComponent,
  HelpCentreCollection: () => HelpCentreCollectionComponent,
  ImageCardSet: () => ImageCardSetComponent,
  ImageOverlayCollection: () => ImageOverlayCollectionComponent,
  ProductBlockCarousel: () => ProductBlockCarouselComponent,
  PropositionBar: () => PropositionBarComponent,
  SectionPeek: () => SectionPeekComponent,
  ShopTheLook: () => ShopTheLookComponent,
  SimpleText: () => SimpleTextComponent,
  FlagshipBanner: () => FlagshipBannerComponent,
  ImageTextBlock: () => ImageTextBlockComponent,
  TabbedSet: () => TabbedSetComponent,
  SocialIconCollection: () => SocialIconCollectionComponent,
  StoryStream: () => StoryStreamComponent,
  ProductShowcase: () => ProductShowcaseComponent,
  ReferralStepGuide: () => ReferralStepGuideComponent,
  VideoGallery: () => VideoGalleryComponent,
  VideoHeroBanner: () => VideoHeroBannerComponent,
  VisualDivider: () => VisualDividerComponent,
  NotSupported: () => NotSupportedComponent,
  BrandsPage: () => BrandsPageComponent,
  StripBanner: () => StripBannerComponent,
  CoverageCalculator: () => CoverageCalculatorComponent,
  SimpleTitleAndCTA: () => SimpleTitleAndCTAComponent,
  AboutUsVideo: () => AboutUsVideoComponent,
  WaitlistSignup: () => WaitlistSignupComponent,
  SwatchSelectSlider: () => SwatchSelectSliderComponent,
  SimpleTextReadMore: () => SimpleTextReadMoreComponent,
  BuildYourOwnBundle: () => BuildYourOwnBundleComponent,
  ImageSelectSlider: () => ImageSelectSliderComponent,
  ShopTheRoom: () => ShopTheRoomComponent,
  TrustPilotCarousel: () => TrustPilotCarouselComponent,
  DispatchDateAndCountdown: () => DispatchDateAndCountdownComponent,
  PersonalisedRecommendations: () => PersonalisedRecommendationsComponent,
}

const widgetRendererLoaderMap: {
  [type in WidgetTypes]?: () => React.ComponentType<any> | null
} = {
  BrandsPageWidget: widgetRendererLoaders.BrandsPage,
  GlobalStripBanner: widgetRendererLoaders.StripBanner,
  coverageCalculator: widgetRendererLoaders.CoverageCalculator,
  simpleCTA: widgetRendererLoaders.SimpleTitleAndCTA,
  simpleTitleWidget: widgetRendererLoaders.SimpleTitleAndCTA,
  swatchSelectSliderSet: widgetRendererLoaders.SwatchSelectSlider,
  AccordionWidget: widgetRendererLoaders.AccordionItem,
  AccordionWidgetContainer: widgetRendererLoaders.AccordionContainer,
  storyStreamWidget: widgetRendererLoaders.StoryStream,
  DynamicReferralWidget: widgetRendererLoaders.DynamicReferral,
  GlobalReferral3StepGuide: widgetRendererLoaders.ReferralStepGuide,
  EditorialWidget: widgetRendererLoaders.SimpleText,
  EmailReEngagementModal: widgetRendererLoaders.EmailEngagementModal,
  GlobalBrandLogos: widgetRendererLoaders.LogoImageCollection,
  GlobalBuyingRightNow: widgetRendererLoaders.BuyingRightNow,
  GlobalBuyingRightNowManualProductURL: widgetRendererLoaders.BuyingRightNow,
  GlobalCardScrollerSet: widgetRendererLoaders.ImageCardSet,
  GlobalEditorialWithFeature: widgetRendererLoaders.Editorial,
  GlobalFourBestSellers: widgetRendererLoaders.BestSellers,
  GlobalFourButtonLink: widgetRendererLoaders.ButtonCollection,
  GlobalFourItemEditorial: widgetRendererLoaders.Editorial,
  GlobalGeneralImageBanner: widgetRendererLoaders.Banner,
  GlobalHelpCentreCollection: widgetRendererLoaders.HelpCentreCollection,
  GlobalImageCardSet: widgetRendererLoaders.ImageCardSet,
  GlobalMultiButton: widgetRendererLoaders.ButtonCollection,
  GlobalPrimaryBanner: widgetRendererLoaders.Banner,
  GlobalPrimaryBannerWithList: widgetRendererLoaders.Banner,
  GlobalPrimaryBannerWithTextOverlay: widgetRendererLoaders.Banner,
  GlobalProductCardScroller: widgetRendererLoaders.BestSellers,
  GlobalScalableLogos: widgetRendererLoaders.LogoImageCollection,
  GlobalSectionPeek: widgetRendererLoaders.SectionPeek,
  GlobalSetAndromeda: widgetRendererLoaders.Editorial,
  GlobalSimpleTextCTAWidget: widgetRendererLoaders.SimpleText,
  GlobalSixItemCategories: widgetRendererLoaders.CategorySet,
  GlobalSocialIconCollection: widgetRendererLoaders.SocialIconCollection,
  GlobalSocialIconCollectionv2: widgetRendererLoaders.SocialIconCollection,
  GlobalTabbedWidgetSet: widgetRendererLoaders.TabbedSet,
  GlobalThreeItemEditorialSubtitleBG: widgetRendererLoaders.Editorial,
  GlobalThreeItemEditorial: widgetRendererLoaders.Editorial,
  GlobalTwoBestSellers: widgetRendererLoaders.FeaturedProducts,
  GlobalTwoItemEditorial: widgetRendererLoaders.Editorial,
  GlobalTwoItemImageTextBlock: widgetRendererLoaders.ImageTextBlock,
  provenanceBrandHeader: widgetRendererLoaders.ImageTextBlock,
  GlobalTwoItemImageTextCTA: widgetRendererLoaders.ImageOverlayCollection,
  GlobalTwoItemImageTextCTA3070: widgetRendererLoaders.NotSupported,
  GlobalUGCCarousel: widgetRendererLoaders.NotSupported,
  GlobalVideoGallery: widgetRendererLoaders.VideoGallery,
  GlobalVideoHeroBannerWidget: widgetRendererLoaders.VideoHeroBanner,
  GlobalVideoTextImageCTA: widgetRendererLoaders.ImageTextBlock,
  GlobalWaitListSignUpFormResponses: widgetRendererLoaders.WaitlistSignup,
  GlobalWaitListSignUpWidget: widgetRendererLoaders.WaitlistSignup,
  GlobalWidgetSirius: widgetRendererLoaders.ImageTextBlock,
  GlobalWidgetVega: widgetRendererLoaders.Banner,
  GlobalYoutubeGalleryItem: widgetRendererLoaders.NotSupported,
  GlobalYoutubeGallerySet: widgetRendererLoaders.NotSupported,
  LoyaltyHubBirthdayGift: widgetRendererLoaders.NotSupported,
  LoyaltyHubTier: widgetRendererLoaders.NotSupported,
  LoyaltyRewardTier: widgetRendererLoaders.NotSupported,
  LoyaltyRewardTiers: widgetRendererLoaders.NotSupported,
  MailingList: widgetRendererLoaders.NotSupported,
  MultipleCTABanner: widgetRendererLoaders.NotSupported,
  ResponsiveBuildYourOwnBundle: widgetRendererLoaders.BuildYourOwnBundle,
  ResponsiveBuildYourOwnBundleProducts: widgetRendererLoaders.NotSupported,
  ResponsiveComparisonTable: widgetRendererLoaders.NotSupported,
  ResponsiveComparisonTableContainer: widgetRendererLoaders.NotSupported,
  ResponsiveProductBlockCarousel: widgetRendererLoaders.ProductBlockCarousel,
  ResponsiveProductTabs: widgetRendererLoaders.TabbedSet,
  ResponsiveSetSalon: widgetRendererLoaders.NotSupported,
  ResponsiveSetSalonWithSlides: widgetRendererLoaders.NotSupported,
  ResponsiveSliderSet: widgetRendererLoaders.BannerSlider,
  ResponsiveSuccessStoriesWidgetContainer: widgetRendererLoaders.NotSupported,
  ResponsiveSuccessStoryWidget: widgetRendererLoaders.NotSupported,
  ResponsiveTwoItemComparisonContainer: widgetRendererLoaders.NotSupported,
  ResponsiveTwoItemComparisonRow: widgetRendererLoaders.NotSupported,
  ResponsiveUSPBar: widgetRendererLoaders.NotSupported,
  SeoProductCategory: widgetRendererLoaders.NotSupported,
  SeoProductCategorySet: widgetRendererLoaders.NotSupported,
  ShopTheLookHotSpot: widgetRendererLoaders.NotSupported,
  SimpleTextReadMore: widgetRendererLoaders.SimpleTextReadMore,
  TopBrandsWidget: widgetRendererLoaders.NotSupported,
  TopProductCategory: widgetRendererLoaders.NotSupported,
  TrustPilotCarouselWidget: widgetRendererLoaders.TrustPilotCarousel,
  TrustPilotWidget: widgetRendererLoaders.NotSupported,
  VoucherCodesWidget: widgetRendererLoaders.NotSupported,
  asymmetricGrid: widgetRendererLoaders.NotSupported,
  buildyourownbundle: widgetRendererLoaders.NotSupported,
  categoryItemCard: widgetRendererLoaders.CategorySetItem,
  eightLinkButtonWidget: widgetRendererLoaders.NotSupported,
  imageSelectCard: widgetRendererLoaders.ImageSelectSlider,
  imageSelectSlider: widgetRendererLoaders.ImageSelectSlider,
  improvedSearchBestSellers: widgetRendererLoaders.NotSupported,
  kitBuilder: widgetRendererLoaders.NotSupported,
  loyaltyRewardsList: widgetRendererLoaders.NotSupported,
  loyaltyWelcomeMessage: widgetRendererLoaders.NotSupported,
  multiCategoryCardSet: widgetRendererLoaders.CategorySet,
  multiCategoryCardSetV2: widgetRendererLoaders.CategorySet,
  parcelLabWidget: widgetRendererLoaders.NotSupported,
  promoproductslider: widgetRendererLoaders.ProductShowcase,
  revieveWidget: widgetRendererLoaders.NotSupported,
  shopTheLook: widgetRendererLoaders.ShopTheLook,
  simpleTextWidgetSet: widgetRendererLoaders.SimpleText,
  simpletextwidget: widgetRendererLoaders.SimpleText,
  simpletextwidgetwithh1: widgetRendererLoaders.SimpleText,
  sponsoredProducts: widgetRendererLoaders.ProductShowcase,
  sponsoredProductsNew: widgetRendererLoaders.NotSupported,
  simpleDivider: widgetRendererLoaders.VisualDivider,
  subscribeAndSaveInformationModal: widgetRendererLoaders.NotSupported,
  swatchSelectSlider: widgetRendererLoaders.SwatchSelectSlider,
  trustReinforcementContainer: widgetRendererLoaders.NotSupported,
  trustReinforcementSection: widgetRendererLoaders.NotSupported,
  GlobalAboutUsVideo: widgetRendererLoaders.AboutUsVideo,
  CriteoSponsoredBannerAds: widgetRendererLoaders.FlagshipBanner,
  sponsoredProductsCriteoHomepage: widgetRendererLoaders.NotSupported,
  qubitHomePageRecs: widgetRendererLoaders.SectionPeek,
  propositionBar: widgetRendererLoaders.PropositionBar,
  ShopTheRoom: widgetRendererLoaders.ShopTheRoom,
  GlobalDispatchAndDateCountdownWidget:
    widgetRendererLoaders.DispatchDateAndCountdown,
  TestWidgetN: widgetRendererLoaders.PersonalisedRecommendations,
  personalisedRecommendations:
    widgetRendererLoaders.PersonalisedRecommendations,
}

export const loadWidget = (type: WidgetTypes) => {
  if (!widgetRendererLoaderMap[type]) {
    return null
  }
  return widgetRendererLoaderMap[type]!()
}
